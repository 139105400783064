import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import PageBody from '../components/PageBody'
import {graphql} from "gatsby"
import Layout from '../components/DefaultLayout'

const PageTemplate = ({data}) => {
    const {title, slug, body} = data.contentfulPage

    return (
        <Layout>
            <div>
                <Helmet>
                    <title>{`${title} - ${config.siteTitle}`}</title>
                    <meta property="og:title" content={`${title} - ${config.siteTitle}`}/>
                    <meta property="og:url" content={`${config.siteUrl}/${slug}/`}/>
                </Helmet>

                <Container>
                    <PageTitle>{title}</PageTitle>
                    <PageBody body={body}/>
                </Container>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default PageTemplate
